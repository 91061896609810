/**
   Component: form-input.less
*/

/**
 * Custom form elements
 *    - Checkbox 
 *    - Radios
 */

@ui-fg-color: @brand-primary;
@ui-bg-color: #ddd;
@ui-input-wd: 20px;

.c-checkbox,
.c-radio {
  margin-right: 4px;
  * {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
  }
  
  // the element that replaces the input
  span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: -@ui-input-wd;
    width: @ui-input-wd; height: @ui-input-wd;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: (@ui-input-wd/4);
    text-align: center;
    &:before {
      margin-left: 1px; // fa align trick
      color: #fff;
    }
  }

  &:hover span {
    border-color: @ui-fg-color;
  }

  .form-inline & span {
    margin-left: 0;
  }

  &.c-checkbox-rounded,
  &.c-radio-rounded {
    span {
      border-radius: 500px;
    }
  }
}

/* override for radio */
.c-radio span {
  border-radius: 500px;
}

/* the icon */
.c-checkbox,
.c-radio {
  span:before {
    //content: "\2713"; /* comment content to use custom icon classes */
    position: absolute;
    top: 0; left: -1px;  right: 0; bottom: 0;
    opacity: 0;
    .scale(.2);
    text-align: center !important;
    font-size: 12px;
    line-height: (@ui-input-wd - 1);
    vertical-align: middle;
  }
}


/* Checked state */
.c-checkbox,
.c-radio {
  input[type=checkbox]:checked + span:before,
  input[type=radio]:checked + span:before{
    opacity: 1;
    .scale(1);
    .transition-transform(.2s ease-out);
  }

  input[type=checkbox]:checked + span,
  input[type=radio]:checked + span {
    border-color: @ui-fg-color;
    background-color: @ui-fg-color;
  }

  /* override for radio */
  input[type=radio]:checked + span{
    background-color: #fff;
    &:before{ 
      color: @ui-fg-color;
    }
  }

  /* Disable state */

  input[type=checkbox]:disabled + span,
  input[type=radio]:disabled + span{
    border-color: @ui-bg-color !important;
    background-color: @ui-bg-color !important;
  }
  /* override for radio */
  input[type=radio]:disabled + span {
    background-color: #fff !important;
    &:before {
      color: @ui-bg-color;
    }
  }
}

.block-note {
  background-image: -webkit-linear-gradient(#eee .1em, transparent .1em);
  background-image: linear-gradient(#eee .1em, transparent .1em);
  
  background-size: 100% 20px;
  background-color: #fff; 

  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none;

  &.block-note-margin {
    background-image: 
      -webkit-linear-gradient(0deg, transparent 49px, lighten(@brand-danger, 30%) 49px, lighten(@brand-danger, 30%) 51px, transparent 51px), -webkit-linear-gradient(#eee .1em, transparent .1em);
    background-image: 
      linear-gradient(90deg, transparent 49px, lighten(@brand-danger, 30%) 49px, lighten(@brand-danger, 30%) 51px, transparent 51px),
      linear-gradient(#eee .1em, transparent .1em);
    padding-left: 55px;
  }
}
